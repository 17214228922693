import { AddressModel } from "../../../Models/AddressModel";
import styles from "./Address.module.css";

const Address = ({
  companyName,
  contactPerson,
  streetNumber,
  streetName,
  postNumber,
  cityName,
}: AddressModel) => {
  return (
    <div className={styles.address}>
      <p>{contactPerson}</p>
      <p className={styles.name}>{companyName}</p>
      <p>
        {streetNumber} {streetName}
      </p>
      <p className={styles.city}>
        {postNumber}, {cityName}
      </p>
    </div>
  );
};

export default Address;
