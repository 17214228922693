import { useContext, useState } from "react";
import { NavLink } from "react-router-dom";

import LocationContext, { Language } from "../../../store/locationContext";
import LangSelect from "./LangSelect/LangSelect";
import LangSwitch from "./LangSwitch/LangSwitch";
import styles from "./Menu.module.css";
import { MenuModel } from "./Models/MenuModel";

type Props = {
  data: MenuModel;
  aboutLength: number;
  myWorkLength: number;
  mobile: boolean;
  active: boolean;
  mobileMenuHandler: Function;
};

//TODO: #6 Some mobile menu background, animation or something like that; maybe a bit of transparency to the text too
const Menu = ({
  data,
  aboutLength,
  myWorkLength,
  mobile,
  active,
  mobileMenuHandler,
}: Props) => {
  const ctx = useContext(LocationContext);
  const [langSelectActive, setLangSelectActive] = useState(false);

  const menuClickHandler =
    (slide?: number) => (event: React.MouseEvent<HTMLAnchorElement>) => {
      ctx.changeSlide(slide ?? 0);
      mobileMenuHandler();
    };

  let menuClasses = mobile ? styles.mobileMenu : styles.menu;

  if (active) {
    menuClasses += " " + styles.active;
  }

  const langClickHandler = () => {
    setLangSelectActive(prev => !prev);
  };

  const langSelectHandler = (lang: Language) => () => {
    ctx.changeLang(lang);
    langClickHandler();
  }

  const langClass = langSelectActive ? styles['lang-active'] : undefined;

  return (
    <>
      <div className={menuClasses}>
        <ul>
          <li>
            <NavLink
              to="/"
              className={({ isActive }) =>
                isActive ? styles.active : undefined
              }
              onClick={menuClickHandler(0)}
            >
              {data.landing}
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/about-me"
              className={({ isActive }) =>
                isActive ? styles.active : undefined
              }
              onClick={menuClickHandler(1)}
            >
              {data.about}
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/my-work"
              className={({ isActive }) =>
                isActive ? styles.active : undefined
              }
              onClick={menuClickHandler(1 + aboutLength)}
            >
              {data.mywork}
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/contact"
              className={({ isActive }) =>
                isActive ? styles.active : undefined
              }
              onClick={menuClickHandler(1 + aboutLength + myWorkLength)}
            >
              {data.contact}
            </NavLink>
          </li>
          <li>
            <LangSwitch onClick={langClickHandler} />
          </li>
        </ul>
      </div>
      <LangSelect className={langClass} onClick={langSelectHandler}/>
    </>
  );
};

export default Menu;
