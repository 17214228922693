import Slide from "../Components/Slide/Slide";
import { MyWorkModel } from "../Models/myWorkModel";

export const processMyWorkData = (
  data: MyWorkModel[],
  onClick: React.MouseEventHandler<HTMLDivElement>
) => {
  return data.map(({ image, title, content, hyperlink }) => (
    <Slide imgUrl={image} imgAlt={title} onClick={onClick}>
      <h2>{title}</h2>
      {content.map((c, i) => (
        <p key={i}>{c}</p>
      ))}
    </Slide>
  ));
};

/* need to add hyperlink logic to Slide component */