import { forwardRef } from "react";

import styles from "./GoToTheWeb.module.css";

type Props = {
  color?: string;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  onMouseOver?: React.MouseEventHandler<HTMLDivElement>;
  onMouseOut?: React.MouseEventHandler<HTMLDivElement>;
};

const GoToTheWeb = forwardRef<HTMLDivElement, Props>(
  ({ color, className, onClick, onMouseOver, onMouseOut }: Props, ref) => {
    const bgColor = color ? color : "black";
    const classes = styles.goto + (className ? " " + className : "");

    return (
      <>
        <div
          className={classes}
          onClick={onClick}
          onMouseOver={onMouseOver}
          onMouseOut={onMouseOut}
          style={{ backgroundColor: bgColor }}
        >
          <div className={styles.container} ref={ref}>
            <div className="text">
              <p>Go to the website &#10132;</p>
            </div>
          </div>
        </div>
      </>
    );
  }
);

export default GoToTheWeb;
