import { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";

import LocationContext from "../../../../store/locationContext";
import styles from "./LangSwitch.module.css";

type Props = {
  onClick: React.MouseEventHandler<HTMLDivElement>;
}

const LangSwitch = ({onClick}: Props) => {
  const ctx = useContext(LocationContext);
  return (
    <div className={styles["lang-icon"]} onClick={onClick}>
      <div className={styles["lang-block"]}>
        <FontAwesomeIcon icon={faGlobe} />
        <span className={styles.lang}> {ctx.lang}</span>
      </div>
    </div>
  );
};

export default LangSwitch;
