export interface MenuModel {
  landing: string;
  about: string;
  mywork: string;
  contact: string;
}

export const isMenuModel = (obj: any): obj is MenuModel => {
  return (
    typeof obj === "object" &&
    typeof obj.landing === "string" &&
    typeof obj.about === "string" &&
    typeof obj.mywork === "string" &&
    typeof obj.contact === "string"
  );
};
