import styles from "./Backdrop.module.css";

const Backdrop = ({
    onClose,
  }: {
    onClose: React.MouseEventHandler<HTMLDivElement>;
  }) => {
    return <div className={styles.backdrop} onClick={onClose}></div>;
  };

export default Backdrop;