export type MyWorkImage = [string, string, string | undefined];

export class MyWorkModel {
  image: string;
  title: string;
  hyperlink?: string;
  content: string[];
  lang: string;

  constructor(
    image: string,
    title: string,
    hyperlink: string | undefined,
    content: string[],
    lang: string
  ) {
    this.image = image;
    this.title = title;
    this.hyperlink = hyperlink;
    this.content = content;
    this.lang = lang;
  }

  static getImage(work: MyWorkModel): MyWorkImage {
    return [work.image, work.title, work.hyperlink];
  }
}

export const isMyWorkModelArray = (obj: any): obj is MyWorkModel[] => {
  return (
    Array.isArray(obj) &&
    obj.every(
      (item) =>
        typeof item === "object" &&
        typeof item.image === "string" &&
        typeof item.title === "string" &&
        (typeof item.hyperlink === "string" || item.hyperlink === undefined) &&
        Array.isArray(item.content) &&
        item.content.every((content: any) => typeof content === "string") &&
        typeof item.lang === "string"
    )
  );
};
