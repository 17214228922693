import { useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { useWheelHandler } from "./useWheelHandler";
import { useSwipeHandler } from "./useSwipeHandler";
import LocationContext from "../store/locationContext";

interface configObject {
  numSlides?: number;
  styles?: { [key: string]: string };
  prevPage?: string;
  nextPage?: string;
  offset?: number;
  first?: boolean;
  last?: boolean;
}

export const useSlideChange = ({
  numSlides,
  styles,
  prevPage,
  nextPage,
  offset,
  first,
  last,
}: configObject) => {
  const ctx = useContext(LocationContext);
  const offsetConstant = offset ? offset : 1;

  const {
    scrolledUp,
    scrolledDown,
    wheelHandler,
    setScrolledDown,
    setScrolledUp,
  } = useWheelHandler();

  const sectionRef = useRef<HTMLDivElement>(null);

  const { swipedUp, swipedDown, handleTouchStart, setSwipedUp, setSwipedDown } =
    useSwipeHandler(sectionRef);

  const navigate = useNavigate();

  useEffect(() => {
    const up = scrolledUp || swipedUp;
    const down = scrolledDown || swipedDown;

    let refClasses: DOMTokenList;
    /* Graphical slide/page change effect */
    if (sectionRef.current && (up || down) && styles) {
      refClasses = sectionRef.current.classList;
      if (!(down && last) && !(up && first)) {
        refClasses.add(styles.slideChange);
      }
    }
    const timeout = setTimeout(() => {
      if (up) {
        if (!first) {
          ctx.changeSlide(ctx.slide - 1);
        }
        setScrolledUp(false);
        setSwipedUp(false);
      }
      if (down) {
        if (ctx.slide <= (numSlides ?? 0) + (offsetConstant - 1)) {
          ctx.changeSlide(ctx.slide + 1);
        }
        setScrolledDown(false);
        setSwipedDown(false);
      }
      if (refClasses && styles) {
        refClasses.remove(styles.slideChange);
      }
    }, 300);

    /* Navigation */
    if (ctx.slide < offsetConstant) {
      if (prevPage) {
        ctx.changePath(prevPage);
        navigate(prevPage);
      }
    }
    if (ctx.slide >= (numSlides ?? 0) + offsetConstant) {
      if (nextPage) {
        ctx.changePath(nextPage);
        navigate(nextPage);
      }
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [
    scrolledDown,
    scrolledUp,
    setScrolledUp,
    setScrolledDown,
    navigate,
    ctx,
    numSlides,
    styles,
    offsetConstant,
    prevPage,
    nextPage,
    first,
    last,
    swipedUp,
    swipedDown,
    setSwipedUp,
    setSwipedDown,
  ]);

  return { wheelHandler, sectionRef, handleTouchStart };
};
