import { useState, useContext } from "react";

import Map from "./Components/Map/Map";
import Section from "../../UI/Components/Section/Section";
import ContactInfo from "./Components/ContactInfo/ContactInfo";
import styles from "./Contact.module.css";
import { useSlideChange } from "../../hooks/useChangeSlide";
import { useLoadPage } from "../../hooks/useLoadPage";
import { ContactModel, ContactTextsModel } from "./Models/ContactModel";
import LocationContext from "../../store/locationContext";
import LegalMentions from "./Components/LegalMentions/LegalMentions";

type Props = {
  texts: ContactTextsModel;
  data: ContactModel;
  offset: number;
};

const Contact = ({ texts, data, offset }: Props) => {
  const [legalMentionsOpen, setLegalMentionsOpen] = useState(false);
  const ctx = useContext(LocationContext);
  const { wheelHandler, handleTouchStart, sectionRef } = useSlideChange({
    styles: styles,
    prevPage: "/my-work",
    offset: offset,
    last: true,
  });

  const legalMentionsHandler: React.MouseEventHandler<HTMLDivElement> = (e) => {
    setLegalMentionsOpen((prev) => !prev);
  };

  const mapScrollHandler: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
  };

  const lang = ctx.lang;

  useLoadPage({ path: "/contact", offset: offset });

  const content = (
    <div className={styles.text}>
      <h2>{texts.headline}</h2>
      {texts.text.map((c, i) => (
        <p key={i}>{c}</p>
      ))}
    </div>
  );

  return (
    <Section
      className={styles.contact}
      wheel={wheelHandler}
      onTouchStart={handleTouchStart}
      ref={sectionRef}
    >
      {legalMentionsOpen && (
        <LegalMentions lang={ctx.lang} onClose={legalMentionsHandler} />
      )}
      {content}
      <ContactInfo
        className={styles["contact-info"]}
        address={data.address}
        emailAddress={data.emailAddress}
        linkedin={data.linkedin}
        phoneNumber={data.phoneNumber}
      />
      <div className={styles.map} onWheel={mapScrollHandler}>
        <Map lat={43.52547} lng={0.16105} />
      </div>
      <div className={styles["legal-mentions"]} onClick={legalMentionsHandler}>
        <p>
          {lang === "en" && "Legal mentions"}
          {lang === "fr" && "Mentions légales"}
          {lang === "cs" && "Právní informace"}
        </p>
      </div>
    </Section>
  );
};

export default Contact;
