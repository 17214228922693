import { useNavigate } from "react-router-dom";
import Section from "../../UI/Components/Section/Section";
import styles from "./Error.module.css";

type Props = {
  name?: string;
  message?: string;
  code?: number;
};

const ErrorBoundary = ({ name, message, code }: Props) => {
  const navigate = useNavigate();
  const errorWheelHandler = () => {
    navigate("/");
  };

  const content = (
    <Section
      wheel={errorWheelHandler}
      onTouchStart={errorWheelHandler}
      className={styles.error}
    >
      <h2>{`${name ? name : "Error"} ${code && code}`}</h2>
      {message && <p>{message}</p>}
    </Section>
  );
  return content;
};

export default ErrorBoundary;
