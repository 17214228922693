import styles from "./Burger.module.css";

type Props = {
  burgerActive: boolean;
  burgerClickHandler: React.MouseEventHandler;
};

const Burger = ({ burgerActive, burgerClickHandler }: Props) => {
  const burgerClasses =
    styles.burger + (burgerActive ? " " + styles["burger-open"] : "");

  const burger = (
    <div className={burgerClasses} onClick={burgerClickHandler}>
      <span className={styles["burger-topBar"]}></span>
      <span className={styles["burger-middleBar"]}></span>
      <span className={styles["burger-bottomBar"]}></span>
    </div>
  );

  return burger;
};

export default Burger;
