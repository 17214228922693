import React, { useEffect, useRef } from "react";

import styles from "./Wrapper.module.css";

type Props = {
    children?: React.ReactNode;
}

const Wrapper = ({children}: Props) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      const wrapper = wrapperRef.current;
      if (!wrapper) return;

      const { top, bottom } = wrapper.getBoundingClientRect();
      const shadow = top < 0 || bottom > window.innerHeight;
      wrapper.style.boxShadow = shadow ? "0 0 10px rgba(0, 0, 0, 0.5)" : "none";
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div ref={wrapperRef} className={styles.wrapper}>
      {children}
    </div>
  );
};

export default Wrapper;
