import { useEffect, useState, useContext } from "react";
import { Route, Routes } from "react-router-dom";

import LocationContext, { Language } from "./store/locationContext";
import TopBar from "./UI/Components/Menu/TopBar";
import Wrapper from "./UI/Components/Wrapper/Wrapper";
import Scrollbar from "./UI/Components/Scrollbar/Scrollbar";
import Landing from "./pages/Landing/Landing";
import About from "./pages/About/About";
import MyWork from "./pages/MyWork/MyWork";
import Contact from "./pages/Contact/Contact";
import ErrorBoundary from "./pages/Error/Error";
import fetchAPIData from "./functions/fetchData";
import { detectLang } from "./functions/detectLang";
import { useBackAction } from "./hooks/useBackAction";

import {
  isMyWorkModelArray,
  MyWorkModel,
} from "./pages/MyWork/Models/myWorkModel";
import { AboutModel, isAboutModelArray } from "./pages/About/Models/aboutModel";
import {
  LandingModel,
  isLandingModel,
} from "./pages/Landing/Models/LandingStructure";
import {
  ContactModel,
  ContactTextsModel,
  isContactModel,
  isContactTextsModel,
} from "./pages/Contact/Models/ContactModel";
import { MenuModel, isMenuModel } from "./UI/Components/Menu/Models/MenuModel";

import Spinner from "./pages/Landing/Components/Spinner/Spinner";

function App() {
  const ctx = useContext(LocationContext);
  /* For later use, now unnecessary */
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState<Error | null>(null);
  const [lang, setLang] = useState<Language | undefined>();

  const [aboutData, setAboutData] = useState<AboutModel[]>([]);
  const [myWorkData, setMyWorkData] = useState<MyWorkModel[]>([]);
  const [landingData, setLandingData] = useState<LandingModel>();
  const [contactData, setContactData] = useState<ContactModel>({});
  const [contactTextsData, setContactTextsData] = useState<ContactTextsModel>();
  const [menuData, setMenuData] = useState<MenuModel>();

  // Disables back button in the browser - too difficult to change it's behaviour towards pleasant user experience
  useBackAction();

  useEffect(() => {
    const langData = detectLang();
    setLang(langData);
  }, []);

  useEffect(() => {
    if (lang) {
      ctx.changeLang(lang);
      ctx.setDefaultLang(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  useEffect(() => {
    const fetchData = async () => {
      setMenuData(
        await fetchAPIData<MenuModel>("api/menu/", ctx.lang, isMenuModel)
      );
      setLandingData(
        await fetchAPIData<LandingModel>(
          "api/landing/",
          ctx.lang,
          isLandingModel
        )
      );
      setAboutData(
        (await fetchAPIData<AboutModel[]>(
          "api/about/",
          ctx.lang,
          isAboutModelArray
        )) ?? []
      );
      setMyWorkData(
        (await fetchAPIData<MyWorkModel[]>(
          "api/my-work/",
          ctx.lang,
          isMyWorkModelArray
        )) ?? []
      );
      setContactData(
        (await fetchAPIData<ContactModel>(
          "api/contact/",
          undefined,
          isContactModel
        )) ?? {}
      );
      setContactTextsData(
        await fetchAPIData<ContactTextsModel>(
          "api/contact/",
          ctx.lang,
          isContactTextsModel
        )
      );
      setIsLoading(false);
    };

    if (!ctx.defaultLang) {
      try {
        fetchData();
      } catch (err) {
        if (err instanceof Error) {
          setHasError(err);
        } else {
          setHasError({
            message: "Unknown error has occured",
            name: "Unknown error",
          });
        }
      }
    }
  }, [ctx.defaultLang, ctx.lang]);

  // TODO: #16 Add Error boundaries
  let content: JSX.Element;

  if (isLoading && !hasError) {
    content = <Spinner />;
  } else if (!isLoading && !hasError) {
    content = (
      <>
        <TopBar
          aboutLength={aboutData.length}
          myWorkLength={myWorkData.length}
          data={menuData!}
        />
        <Wrapper>
          <Routes>
            <Route path="/" element={<Landing data={landingData!} />} />
            <Route path="/about-me" element={<About data={aboutData} />} />
            <Route
              path="/my-work"
              element={
                <MyWork data={myWorkData} aboutDataLength={aboutData.length} />
              }
            />
            <Route
              path="/contact"
              element={
                <Contact
                  offset={1 + aboutData.length + myWorkData.length}
                  data={contactData}
                  texts={contactTextsData!}
                />
              }
            />
            <Route
              path="*"
              element={
                <ErrorBoundary
                  code={404}
                  name="Page not found"
                  message="There is nothing here. Scroll or swipe to return to the main page."
                />
              }
            />
          </Routes>
          <Scrollbar
            aboutSlideCount={aboutData.length}
            myWorkSlideCount={myWorkData.length}
          />
        </Wrapper>
      </>
    );
  } else {
    if (hasError) {
      content = (
        <ErrorBoundary name={hasError.name} message={hasError.message} />
      );
    } else {
      content = <ErrorBoundary code={404} />;
    }
  }

  return content;
}

export default App;
