import React, { forwardRef } from "react";

import styles from "./Section.module.css";

type Props = {
  children?: React.ReactNode;
  className?: string;
  wheel: React.WheelEventHandler<HTMLElement>;
  onTouchStart: React.TouchEventHandler<HTMLElement>;
};

const Section = forwardRef<HTMLDivElement, Props>(
  (
    {
      children,
      className,
      wheel,
      onTouchStart
    }: Props,
    ref
  ) => {
    let classes = styles.section + " " + (className ?? "");

    return (
      <section
        className={classes}
        onWheel={wheel}
        onTouchStart={onTouchStart}
        ref={ref}
      >
        {children}
      </section>
    );
  }
);

export default Section;
