import { useState, useCallback } from "react";

interface SwipeHandler {
  swipedUp: boolean;
  swipedDown: boolean;
  handleTouchStart: React.TouchEventHandler<HTMLDivElement>;
  setSwipedUp: (value: boolean) => void;
  setSwipedDown: (value: boolean) => void;
}

export const useSwipeHandler = (
  elementRef: React.RefObject<HTMLDivElement>
): SwipeHandler => {
  const [swipedUp, setSwipedUp] = useState(false);
  const [swipedDown, setSwipedDown] = useState(false);

  const handleTouchStart = useCallback(
    (e: React.TouchEvent<HTMLDivElement>) => {
      const touch = e.touches[0];
      const touchStart = touch.clientY;
      let direction: string;

      const handleTouchMove = (event: TouchEvent) => {
        event.preventDefault();
        const currentTouch = event.touches[0];
        const diff = currentTouch.clientY - touchStart;

        if (Math.abs(diff) > 20) {
          if (diff > 0) {
            direction = "up";
          } else {
            direction = "down";
          }
        }
      };

      const handleTouchEnd = () => {
        if (elementRef.current) {
          elementRef.current.removeEventListener("touchmove", handleTouchMove);
          elementRef.current.removeEventListener("touchend", handleTouchEnd);

          if (direction === "up") {
            setSwipedUp(true);
          } else if(direction === "down") {
            setSwipedDown(true);
          }
        }
      };

      const handleTouchCancel = () => {
        if (elementRef.current) {
          elementRef.current.removeEventListener("touchmove", handleTouchMove);
          elementRef.current.removeEventListener("touchend", handleTouchEnd);
          elementRef.current.removeEventListener(
            "touchcancel",
            handleTouchCancel
          );
        }
      };

      if (elementRef.current) {
        elementRef.current.addEventListener("touchmove", handleTouchMove, {passive: false});
        elementRef.current.addEventListener("touchend", handleTouchEnd);
        elementRef.current.addEventListener("touchcancel", handleTouchCancel);
      }
    },
    [elementRef]
  );

  return {
    swipedUp,
    swipedDown,
    handleTouchStart,
    setSwipedUp,
    setSwipedDown,
  };
};
