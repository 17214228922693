import { useContext } from "react";
import { useLocation } from "react-router-dom";

import LocationContext from "../../../store/locationContext";
import ScrollbarIcon from "./ScrollbarIcon";
import styles from "./Scrollbar.module.css";

type Props = {
  aboutSlideCount: number;
  myWorkSlideCount: number;
};

const Scrollbar = ({ aboutSlideCount, myWorkSlideCount }: Props) => {
  const location = useLocation();
  const ctx = useContext(LocationContext);

  const aboutSlides: JSX.Element[] = [];
  const myWorkSlides: JSX.Element[] = [];

  for (let i = 0; i < aboutSlideCount; i++) {
    aboutSlides.push(
      <ScrollbarIcon
        active={
          location.pathname === "/about-me" && ctx.slide === (i + 1) ? true : false
        }
        key={i+1}
      />
    );
  }

  for (let i = 0; i < myWorkSlideCount; i++) {
    myWorkSlides.push(
      <ScrollbarIcon
        active={
          location.pathname === "/my-work" && ctx.slide === (i + 1 + aboutSlideCount) ? true : false
        }
        key={i + aboutSlideCount + 1}
      />
    );
  }

  return (
    <div className={styles.scrollbar}>
      <ScrollbarIcon key={0} active={location.pathname === "/" ? true : false} />
      {aboutSlides}
      {myWorkSlides}
      <ScrollbarIcon key={aboutSlideCount + myWorkSlideCount + 1} active={location.pathname === "/contact" ? true : false} />
    </div>
  );
};

export default Scrollbar;
