export interface LandingModel {
  myNameIs: string;
  skills: [string, string, string];
  located: string;
  text: string[];
}

export const isLandingModel = (obj: any): obj is LandingModel => {
  return (
    typeof obj === "object" &&
    typeof obj.myNameIs === "string" &&
    typeof obj.located === "string" &&
    Array.isArray(obj.skills) &&
    obj.skills.length === 3 &&
    obj.skills.every((skill: any) => typeof skill === "string") &&
    Array.isArray(obj.text) &&
    obj.text.every((textItem: any) => typeof textItem === "string")
  );
};
