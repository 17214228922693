import { Language } from "../store/locationContext";

const fetchAPIData = async <T>(
  url: string,
  lang?: Language,
  typeGuard?: (obj: any) => obj is T
): Promise<T | undefined> => {
  const fetchURI = process.env.REACT_APP_API_URI + url + (lang ? lang : "");

  try {
    const response = await fetch(fetchURI, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
      },
    });

    const data = await response.json();

    if (data && data.body) {
      if (typeGuard) {
        if (typeGuard(data.body)) {
          return data.body as T;
        } else {
          throw new Error("Data type mismatch: " + url);
        }
      }
      return data.body as T;
    } else {
      throw new Error("Problem getting " + url + " data");
    }
  } catch (err) {
    throw err;
  }
};

export default fetchAPIData;
