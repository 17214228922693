import { useSlideChange } from "../../hooks/useChangeSlide";
import { LandingModel } from "./Models/LandingStructure";
import Section from "../../UI/Components/Section/Section";
import styles from "./Landing.module.css";

type Props = {
  data: LandingModel;
};

const Landing = ({ data }: Props) => {
  const { wheelHandler, handleTouchStart, sectionRef } = useSlideChange({
    styles: styles,
    nextPage: "/about-me",
    first: true,
  });

  const content = (
    <Section
      className={styles.intro}
      wheel={wheelHandler}
      onTouchStart={handleTouchStart}
      ref={sectionRef}
    >
      <h1>{data.myNameIs}</h1>
      <h2 id={styles.developer}>{data.skills[0]}</h2>
      <h2 id={styles.producer}>{data.skills[1]}</h2>
      <h2 id={styles.musician}>{data.skills[2]}</h2>
      <h3 id={styles.lieu}>{data.located}</h3>
      {data.text.map((c, i) => (
        <p className={styles["landing-text"]} key={i}>
          {c}
        </p>
      ))}
    </Section>
  );

  return content;
};

export default Landing;
