import { useContext } from "react";

import { useSlideChange } from "../../hooks/useChangeSlide";
import { useLoadPage } from "../../hooks/useLoadPage";
import LocationContext from "../../store/locationContext";

import Section from "../../UI/Components/Section/Section";
import styles from "./About.module.css";

import { AboutModel } from "./Models/aboutModel";
import { processAboutData } from "./Utils/processAboutData";

const About = ({ data }: { data: AboutModel[] }) => {
  const ctx = useContext(LocationContext);
  const { wheelHandler, handleTouchStart, sectionRef } = useSlideChange({
    numSlides: data.length,
    styles: styles,
    prevPage: "/",
    nextPage: "/my-work",
  });

  useLoadPage({ path: "/about-me" });

  // Our data
  const processedData = processAboutData(data);
  const content = processedData[ctx.slide - 1];

  return (
    <Section
      className={styles.about}
      wheel={wheelHandler}
      onTouchStart={handleTouchStart}
      ref={sectionRef}
    >
      {content}
    </Section>
  );
};

export default About;
