import {
  faEnvelope,
  faHouse,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import {
  faLinkedin,
  faFacebook,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";

import { ContactModel } from "../../Models/ContactModel";
import styles from "./ContactInfo.module.css";
import Address from "./Address/Address";
import ContactBox from "./ContactBox/ContactBox";

interface Props extends ContactModel {
  className?: string;
}

const ContactInfo = ({
  address,
  phoneNumber,
  emailAddress,
  facebook,
  linkedin,
  instagram,
  className,
}: Props) => {
  const classes = styles["contact-info"] + className ? " " + className : "";

  return (
    <>
      <div className={classes}>
        {address && (
          <ContactBox icon={faHouse}>
            <Address
              cityName={address.cityName}
              postNumber={address.postNumber}
              streetNumber={address.streetNumber}
              companyName={address.companyName}
              streetName={address.streetName}
              contactPerson={address.contactPerson}
            />
          </ContactBox>
        )}

        {phoneNumber && (
          <ContactBox icon={faPhone}>
            {phoneNumber.map((p, i) => (
              <p key={i}>
                <a href={`tel:${p.replace(/\s+/g, "")}`}>{p}</a>
              </p>
            ))}
          </ContactBox>
        )}

        {emailAddress && (
          <ContactBox link={`mailto:${emailAddress}`} icon={faEnvelope}>
            <p>
              <a href={`mailto:${emailAddress}`}>{emailAddress}</a>
            </p>
          </ContactBox>
        )}
      </div>
      {(linkedin || facebook || instagram) && (
        <div className={classes}>
          {linkedin && (
            <ContactBox link={`mailto:${linkedin}`} icon={faLinkedin} />
          )}
          {facebook && (
            <ContactBox link={`mailto:${facebook}`} icon={faFacebook} />
          )}
          {instagram && (
            <ContactBox link={`mailto:${instagram}`} icon={faInstagram} />
          )}
        </div>
      )}
    </>
  );
};

export default ContactInfo;
