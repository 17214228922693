import { useEffect, useContext } from "react";

import LocationContext from "../store/locationContext";

type args = {
  path?: string;
  offset?: number;
};

export const useLoadPage = async ({ path, offset }: args) => {
  const ctx = useContext(LocationContext);
  useEffect(() => {
    if (!ctx.touched) {
      ctx.setTouched(true);
      /* Should happen only if not touched, means we didn't use navigation yet! */
      if (path) {
        ctx.changePath(path);
        if (offset) {
          ctx.changeSlide(offset);
        } else {
          ctx.changeSlide(1);
        }
      }
    }
  });
};
