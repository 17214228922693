import { Language } from "../../../../store/locationContext";
import Modal from "../../../../UI/Components/Modal/Modal";
import styles from "./LegalMentions.module.css";

type Props = {
  onClose: React.MouseEventHandler<HTMLDivElement>;
  lang: Language;
};

const LegalMentions = ({ onClose, lang }: Props) => {
  const enterpreneur = (
    <div>
      <h3>
        {lang === "en" && `Legal information:`}
        {lang === "fr" && `Informations légales:`}
        {lang === "cs" && `Právní informace:`}
      </h3>
      <p className={styles.bold}>Jan Majerníček (e.i.)</p>
      <p>15 rue Joseph Abeilhe</p>
      <p>32230 Marciac</p>
      <p>Tel: +33 06 18 01 45 70</p>
      <p>Siret : 91924242000017</p>
      <p>N° TVA: FR6919242420</p>
    </div>
  );

  const hosting = (
    <div>
      <h3>
        {lang === "en" && `Website hosted by:`}
        {lang === "fr" && `Hébergement:`}
        {lang === "cs" && `Hosting:`}
      </h3>
      <p className={styles.bold}>WEDOS Internet, a. s.</p>
      <p>Masarykova 1230</p>
      <p>373 41, Hluboká nad Vltavou</p>
      <p>
        {lang === "en" && `Czech Republic`}
        {lang === "fr" && `République Tchèque`}
        {lang === "cs" && `Česká republika`}
      </p>
      <p>+420 380 999 333</p>
    </div>
  );

  const cookies = (
    <div>
      <h3>
        {lang === "en" && `Privacy policy:`}
        {lang === "fr" && `Politique de confidentialité:`}
        {lang === "cs" && `Ochrana soukromí:`}
      </h3>
      <p>
        {lang === "en" &&
          `The website doesn't use any cookies and doesn't store user data.`}
        {lang === "fr" &&
          `Le site web n'utilise pas de cookies et ne stocke pas les données des utilisateurs.`}
        {lang === "cs" &&
          `Stránka nepoužívá cookies a neukládá uživatelská data.`}
      </p>
      <p>&nbsp;</p>
      <p>
        {lang === "en" &&
          `User actions may be tracked and user data stored by Google Maps Javascript API.`}
        {lang === "fr" &&
          `Les actions de l'utilisateur peuvent être suivies et les données de l'utilisateur stockées par l'API Javascript de Google Maps.`}
        {lang === "cs" &&
          `Uživatelské akce mohou být sledovány a data ukládána službou Google Maps Javascript API.`}
      </p>
      <p>
        Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Ireland
      </p>
    </div>
  );

  return (
    <Modal onClose={onClose} className={styles["legal-mentions"]}>
      {enterpreneur}
      {hosting}
      {cookies}
    </Modal>
  );
};

export default LegalMentions;
