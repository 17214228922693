import { AddressModel, isAddressModel } from "./AddressModel";

export interface ContactModel {
  address?: AddressModel;
  phoneNumber?: string[];
  emailAddress?: string;
  facebook?: string;
  linkedin?: string;
  instagram?: string;
}

export const isContactModel = (obj: any): obj is ContactModel => {
  return (
    typeof obj === "object" &&
    (isAddressModel(obj.address) || obj.address === undefined) &&
    (Array.isArray(obj.phoneNumber) || obj.phoneNumber === undefined) &&
    obj.phoneNumber.every((phone: any) => typeof phone === "string") &&
    (typeof obj.emailAddress === "string" || obj.emailAddress === undefined) &&
    (typeof obj.facebook === "string" || obj.facebook === undefined) &&
    (typeof obj.linkedin === "string" || obj.linkedin === undefined) &&
    (typeof obj.instagram === "string" || obj.instagram === undefined)
  );
};

export interface ContactTextsModel {
  headline: string;
  text: string[];
}

export const isContactTextsModel = (obj: any): obj is ContactTextsModel => {
  return (
    typeof obj === "object" &&
    typeof obj.headline === "string" &&
    Array.isArray(obj.text) &&
    obj.text.every((text: any) => typeof text === "string")
  );
};