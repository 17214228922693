import React, { useState, useContext } from "react";

import { useSlideChange } from "../../hooks/useChangeSlide";
import LocationContext from "../../store/locationContext";
import Section from "../../UI/Components/Section/Section";
import BigImage from "./Components/BigImage/BigImage";
import styles from "./MyWork.module.css";

/* MyWork model */
import { MyWorkModel, MyWorkImage } from "./Models/myWorkModel";

/* to be replaced by real data logic later */
import { processMyWorkData } from "./Utils/processMyWorkData";
import { useLoadPage } from "../../hooks/useLoadPage";

const MyWork = ({
  data,
  aboutDataLength,
}: {
  data: MyWorkModel[];
  aboutDataLength: number;
}) => {
  const ctx = useContext(LocationContext);

  const offset = 1 + aboutDataLength;
  useLoadPage({ path: "/my-work", offset: offset });

  const [imageIsOpen, setImageIsOpen] = useState(false);
  const { wheelHandler, handleTouchStart, sectionRef } = useSlideChange({
    numSlides: data.length,
    styles: styles,
    prevPage: "/about-me",
    nextPage: "/contact",
    offset: offset,
  });

  /* Overlay open and close logic */

  const overlayOpenHandler = (e: React.MouseEvent<HTMLImageElement>) => {
    e.preventDefault();
    setImageIsOpen(true);
  };

  const overlayCloseHandler = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    setImageIsOpen(false);
  };

  const scrollCheckHandler = (e: React.WheelEvent<HTMLDivElement>) => {
    // Prevents crashing on scrolling when image is open
    if(imageIsOpen){
      setImageIsOpen(false)
    }
    wheelHandler(e);
  }

  /* Our data */
  const processedData = processMyWorkData(data, overlayOpenHandler);
  const content = processedData[ctx.slide - offset];
  const images: MyWorkImage[] = data.map(MyWorkModel.getImage);

  return (
    <Section
      className={styles.mywork}
      wheel={scrollCheckHandler}
      onTouchStart={handleTouchStart}
      ref={sectionRef}
    >
      {imageIsOpen &&
        ctx.slide >= offset &&
        ctx.slide <= offset + data.length && (
          <BigImage
            image={images[ctx.slide - offset][0]}
            alt={images[ctx.slide - offset][1]}
            targetWebsite={images[ctx.slide - offset][2]}
            onClose={overlayCloseHandler}
          ></BigImage>
        )}
      {content}
    </Section>
  );
};

export default MyWork;
