import styles from "./Slide.module.css";

type Props = {
  onClick: React.MouseEventHandler<HTMLImageElement>;
  children?: React.ReactNode;
  className?: string;
  imgUrl?: string;
  imgAlt?: string;
};

const Slide = ({ children, className, imgUrl, imgAlt, onClick }: Props) => {
  const image = imgUrl ? (
    <div className={styles["image__container"]}>
      <img
        src={
          process.env.NODE_ENV === "development"
            ? `https://localhost:8433/${imgUrl}`
            : imgUrl
        }
        alt={imgAlt}
        onClick={onClick}
      />
    </div>
  ) : null;

  return (
    <>
      {image}
      <div className={styles.text + " " + className}>{children}</div>
    </>
  );
};

export default Slide;
