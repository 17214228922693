import { useContext } from "react";
import { NavLink } from "react-router-dom";

import LocationContext from "../../../store/locationContext";
import styles from "./Logo.module.css";

type Props = {
  src: string;
  mobileMenuHandler: Function;
  className?: string;
};

const Logo = ({ src, className, mobileMenuHandler }: Props) => {
  const ctx = useContext(LocationContext);

  const logoClickHandler = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    ctx.changeSlide(0);
    ctx.changePath("/");
    mobileMenuHandler();
  };

  return (
    <div className={styles.logo + (className ? " " + className : "")}>
      <NavLink to="/" onClick={logoClickHandler}>
        <img src={src} alt="logo" />
      </NavLink>
    </div>
  );
};

export default Logo;
