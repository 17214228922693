import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import SocialsIcon from "../SocialsIcon/SocialsIcon";
import styles from "./ContactBox.module.css";

type Props = {
  icon: IconDefinition;
  link?: string;
  children?: React.ReactNode;
};
const ContactBox = ({ icon, link, children }: Props) => {
  return (
    <div className={styles["contact-box"]}>
      <SocialsIcon link={link} icon={<FontAwesomeIcon icon={icon} />} />
      {children && <div>{children}</div>}
    </div>
  );
};

export default ContactBox;
