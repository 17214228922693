export interface AddressModel {
  companyName?: string;
  contactPerson?: string;
  streetNumber: string;
  streetName?: string;
  postNumber: string;
  cityName: string;
}
export const isAddressModel = (obj: any): obj is AddressModel => {
  return (
    typeof obj === "object" &&
    (typeof obj.companyName === "string" || obj.companyName === undefined) &&
    (typeof obj.contactPerson === "string" ||
      obj.contactPerson === undefined) &&
    typeof obj.streetNumber === "string" &&
    (typeof obj.streetName === "string" || obj.streetName === undefined) &&
    typeof obj.postNumber === "string" &&
    typeof obj.cityName === "string"
  );
};
