import { Language, languages } from "../../../store/locationContext";

export interface AboutModel {
  image: string;
  title: string;
  content: string[];
  lang: Language;
}

export const isAboutModelArray = (obj: any): obj is AboutModel[] => {
  return (
    Array.isArray(obj) &&
    obj.every(
      (item) =>
        typeof item === "object" &&
        typeof item.image === "string" &&
        typeof item.title === "string" &&
        Array.isArray(item.content) &&
        item.content.every((content: any) => typeof content === "string") &&
        typeof item.lang === "string" &&
        languages.includes(item.lang)
    )
  );
};
