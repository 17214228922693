import styles from "./ScrollbarIcon.module.css";

type Props = {
  src?: string;
  alt?: string;
  active?: boolean;
};

const ScrollbarIcon = ({ src, alt, active }: Props) => {
  const classes = styles.dot + (active ? " " + styles.active : "");
  const icon = src ? (
    <img src={src} alt={alt && "icon"} />
  ) : (
    <div className={classes}></div>
  );

  return <div className={styles.icon}>{icon}</div>;
};

export default ScrollbarIcon;
