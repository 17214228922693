import Slide from "../Components/Slide/Slide";
import { AboutModel } from "../Models/aboutModel";

export const processAboutData = (data: AboutModel[]) => {
  return data.map(({ image, title, content }) => (
    <Slide imgUrl={image} imgAlt={title}>
      <h2>{title}</h2>
      {content.map((c, i) => (
        <p key={i}>{c}</p>
      ))}
    </Slide>
  ));
};
