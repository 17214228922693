import Modal from "../../../../UI/Components/Modal/Modal";
import styles from "./BigImage.module.css";

type Props = {
  onClose: React.MouseEventHandler<HTMLDivElement>;
  image: string;
  alt?: string;
  targetWebsite?: string;
};

const BigImage = ({ onClose, image, alt, targetWebsite }: Props) => {
  const content = (
    <img
      src={
        process.env.NODE_ENV === "development"
          ? `https://localhost:8433/${image}`
          : image
      }
      alt={alt ? alt : image}
      className={styles.image}
    />
  );

  return (
    <Modal onClose={onClose} targetWebsite={targetWebsite}>
      {content}
    </Modal>
  );
};

export default BigImage;
