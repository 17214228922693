import styles from "./SocialsIcon.module.css";

// Taken from VasekKokes.cz, needs to be adjusted properly
const SocialsIcon = ({ link, icon }: { link?: string; icon: JSX.Element }) => {
  const classes = `${styles.icon}`;
  return (
    <div className={classes}>
      {link && (
        <a href={link} target="_blank" rel="noreferrer">
          {icon}
        </a>
      )}
      {!link && <span>{icon}</span>}
    </div>
  );
};

export default SocialsIcon;
