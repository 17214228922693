import { useState } from "react";

import { Language, languages, LocContextProps } from "./locationContext";
import LocationContext from "./locationContext";

type Props = {
  children: React.ReactNode;
};

const LocationProvider = ({ children }: Props) => {
  const [path, setPath] = useState("/");
  const [slide, setSlide] = useState(0);
  const [touched, setTouched] = useState(false);
  const [lang, setLang] = useState<Language>("en");
  const [defaultLang, setDefaultLang] = useState(true);

  // If the language matches our selection, this is the function to change it
  const changeLang = (inputLang: string) => {
    if (typeof inputLang === "string" && languages.includes(inputLang)) {
      setLang(inputLang as Language);
    }
  };

  const locContext: LocContextProps = {
    touched: touched,
    path: path,
    slide: slide,
    lang: lang,
    defaultLang: defaultLang,
    setTouched: setTouched,
    changePath: setPath,
    changeSlide: setSlide,
    changeLang: changeLang,
    setDefaultLang: setDefaultLang
  };

  return (
    <LocationContext.Provider value={locContext}>
      {children}
    </LocationContext.Provider>
  );
};

export default LocationProvider;
