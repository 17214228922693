import { Language, languages } from "../store/locationContext";

export const detectLang = (): Language => {
  const queryParams = new URLSearchParams(window.location.search);
  if (queryParams && queryParams.get("lang")) {
    const paramsLang = queryParams.get("lang");
    if (paramsLang && languages.includes(paramsLang)) {
      return paramsLang as Language;
    }
  } else {
    const userLang = navigator.language.substring(0, 2);
    if (userLang && languages.includes(userLang)) {
      return userLang as Language;
    }
  }
  return "en";
};
