import { useState, useCallback } from 'react';

// Hook for mouse wheel navigation
export const useWheelHandler = () => {
  const [scrolledUp, setScrolledUp] = useState(false);
  const [scrolledDown, setScrolledDown] = useState(false);

  const wheelHandler = useCallback(
    (event: React.WheelEvent<HTMLDivElement>) => {
      // event.preventDefault();
      if (event.deltaY > 0) {
        setScrolledDown(true);
      } else {
        setScrolledUp(true);
      }
    },
    []
  );

  return { scrolledUp, scrolledDown, wheelHandler, setScrolledUp, setScrolledDown };
}