import { createPortal } from "react-dom";

import ModalOverlay from "./ModalOverlay";
import Backdrop from "./Backdrop";

const portalElement: HTMLElement = document.getElementById("overlays")!;

type Props = {
  onClose: React.MouseEventHandler<HTMLDivElement>;
  children: React.ReactNode;
  targetWebsite?: string;
  className?: string;
};

const Modal = ({ onClose, children, targetWebsite, className }: Props) => {
  return (
    <>
      {createPortal(<Backdrop onClose={onClose} />, portalElement)}
      {createPortal(
        <ModalOverlay
          onClose={onClose}
          targetWebsite={targetWebsite}
          className={className ?? ""}
        >
          {children}
        </ModalOverlay>,
        portalElement
      )}
    </>
  );
};

export default Modal;
