import { forwardRef } from "react";

import styles from "./CloseButton.module.css";

type Props = {
  color?: string;
  className?: string;
  onClick: React.MouseEventHandler<HTMLDivElement>;
};

const CloseButton = forwardRef<HTMLDivElement, Props>(
  ({ color, className, onClick }: Props, ref) => {
    const bgColor = color ? color : "black";
    const classes = styles.close + (className ? " " + className : "");

    return (
      <div className={classes} onClick={onClick} ref={ref}>
        <div
          className={styles["close-diagonal__left"]}
          style={{ backgroundColor: bgColor }}
        ></div>
        <div
          className={styles["close-diagonal__right"]}
          style={{ backgroundColor: bgColor }}
        ></div>
      </div>
    );
  }
);

export default CloseButton;
