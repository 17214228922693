import { useContext } from "react";

import { Language, languages } from "../../../../store/locationContext";
import LocationContext from "../../../../store/locationContext";
import styles from "./LangSelect.module.css";

type Props = {
  className?: string;
  onClick: (lang: Language) => () => void;
};

const LangSelect = ({ className, onClick }: Props) => {
  const ctx = useContext(LocationContext);

  const filteredLanguages = languages.filter((e) => e !== ctx.lang);

  const classes = styles["lang-select"] + (className ? " " + className : "");

  const content = filteredLanguages.map((c, i) => (
    <li key={i} className={styles["lang-element"]} onClick={onClick(c as Language)}>
      {c}
    </li>
  ));

  return <ul className={classes}>{content}</ul>;
};

export default LangSelect;
