import { useState, useEffect, useRef } from "react";
import { Loader } from "@googlemaps/js-api-loader";

import styles from "./Map.module.css";

type Props = {
  lat: number;
  lng: number;
  className?: string;
};

const Map = ({ lat, lng, className }: Props) => {
  const mapRef = useRef<HTMLDivElement | null>(null);
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [error, setError] = useState<string | null>(null);

  const classes = `${styles.map} ${className}`;

  // Google maps inicialization

  useEffect(() => {
    const loader = new Loader({
      apiKey: process.env.REACT_APP_API_KEY ?? "",
      version: "weekly",
    });

    const loadMap = async () => {
      try {
        const google = await loader.load();
        if (mapRef.current) {
          const map = new google.maps.Map(mapRef.current, {
            center: { lat, lng },
            zoom: 18,
            mapId: "aa7df8bd6ec11184",
            disableDefaultUI: true,
          });

          new google.maps.Marker({
            position: { lat, lng },
            map,
          });

          setMap(map);
        }
      } catch (error) {
        console.log("Error: " + (error as Error).message);
        if (error instanceof Error) {
          setError(`Error loading map: ${error.message}`);
        } else {
          setError(`Error loading map: ${error}`);
        }
      }
    };

    loadMap();
  }, [lat, lng]);

  let content: JSX.Element;

  if (!map) {
    // Create a loader component insted of this dumb text, could use the one from the Toradovci website
    content = <p className={styles.loading}>Loading...</p>;
  } else if (error) {
    content = <p className={styles.error}>{error}</p>;
  } else {
    content = <p className={styles.error}>Unknown error...</p>;
  }

  return (
    <div className={classes} ref={mapRef}>
      {content}
    </div>
  );
};

export default Map;
