import styles from "./Spinner.module.css";

const Spinner = () => {
  return (
    <div className={styles["spinner__container"]}>
      <div className={styles.spinner}>
        <div className={styles["spinner__line"]}>&#123;&#125;</div>
        <div className={styles["spinner__line"]}>
          <div>&lt;&gt;</div>
          <div>[]</div>
        </div>
        <div className={styles["spinner__line"]}>@</div>
      </div>
    </div>
  );
};

export default Spinner;
